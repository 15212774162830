@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --color-pink: #ff0052;
  --ctx-blue: #0c0020;
}
a,
a:visited,
a:hover,
a:focus {
  text-decoration: none !important;
}
a:hover {
  color: inherit;
}
body {
  margin: 0;
  font-family: 'Monserrat', sans-serif !important;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* utils */
.text-pink {
  color: var(--color-pink);
}
.bg-ctx-blue{
  background: var(--ctx-blue)
}
.max-w-968{
  max-width: 968px;
}
.mlr-auto{
  margin-right: auto;
  margin-left: auto;
}

/* body */
body {
  background: #1f143d;
}
/* auth page */
.auth-card-bg {
  background: var(--ctx-blue);
}
/* navigation */
.site_logo {
  width: 116px;
}
.brand-title {
  font-size: 1rem;
  font-weight: bold;
  background-color: rgba(28, 28, 52, 0.8);
}
.navigation .nav-container,
.user-layout,
.footer-wrapper {
  max-width: 1100px;
  margin: 0 auto;
}
.navigation {
  background: rgba(31, 41, 55, 0.5);
}
.navigation .subnavigation {
  max-width: 768px;
  margin: 0 auto;
  background: rgba(28, 28, 52, 0.9);
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}

/* slider */
.game-slider-poster {
  height: 500px;
}
/* product grid */
.products-grid {
  grid-template-columns: repeat(4, 1fr);
}
.gameThumbnail {
  height: 400px;
}
.gameThumbnail.gameThumbnailLarge {
  height: 700px !important;
}
.game-details-container {
  background: var(--ctx-blue);
  border-radius: 4px;
}
/* footer */
.footer-container {
  background: #0c0020;
}

/* rsuite reset */
.rs-breadcrumb-item-active {
  opacity: 0.7;
}

/* cart */
.cart-circle {
  height: 80px;
  width: 80px;
  border-radius: 100%;
}
.empty-cart{
  max-width: 100%;
  width: 320px;
}
.empty-cart .rs-panel-body{
  width: 100%;
}